import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Seminar from "../views/Seminar.vue";
import Privacy from "../views/Privacy.vue";
import TradeRow from "../views/TradeRow.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      menuColorPattern: 0,
    },
  },
  {
    path: "/seminar",
    name: "Seminar",
    component: Seminar,
    meta: {
      menuColorPattern: 1,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      menuColorPattern: 0,
    },
  },
  {
    path: "/tradeRow",
    name: "TradeRow",
    component: TradeRow,
    meta: {
      menuColorPattern: 0,
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      menuColorPattern: 0,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
