<template>
  <div>
    <div class="text__wrap">
      <p class="text">お客様から支持される理由</p>
      <ul>
        <Strength
          v-for="(content, index) in strengthPoint"
          :key="index"
          :number="content.number"
          :title="content.title"
          :description="content.description"
        />
      </ul>
      <div class="img"></div>
    </div>
  </div>
</template>

<script>
import Strength from "./SeminarServiceStrength.vue";
import strengthPoint from "@/assets/json/serviceStrength.json";
export default {
  components: {
    Strength,
  },
  data() {
    return {
      strengthPoint: strengthPoint,
    };
  },
};
</script>
<style lang="scss" scoped>
.text__wrap {
  background-color: #463e38;
  padding-bottom: 36px;
  .text {
    color: #fff;
    margin: 0;
    padding: 36px 0 18px 0;
    font-size: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .img {
    width: 80%;
    margin: auto;
    position: relative;
    text-align: center;
    background-image: url(../assets/img/seminar/seminar_support.jpg);
    height: 28vh;
    background-size: contain;
  }
}
@media (min-width: 768px) {
  .text__wrap {
    background-color: #463e38;
    padding: 36px 0;
    background-image: url(../assets/img/seminar/seminar_support.jpg);
    background-size: cover;
    position: relative;
    .text {
      color: #fff;
      margin: 0;
      padding: 80px 0 54px 0;
      font-size: 24px;
      z-index: 1;
      position: relative;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      list-style: none;
      margin: auto;
    }
    .img {
      width: 80%;
      margin: auto;
      position: relative;
      text-align: center;
      background-image: none;
      height: 0vh;
      background-size: contain;
    }
  }
  .text__wrap:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>
