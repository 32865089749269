<template>
  <div class="hamburger__container">
    <!-- <div class="main_logo">
      <router-link to="/"
        ><img
          alt="株式会社F.G.L,愛知県,名古屋市,資産運用コンサルティング"
          src="../assets/img/top/logo.png"
      /></router-link>
    </div> -->
    <!--ハンバーガーメニューのボタン-->
    <div
      class="hamburger__btn"
      @click="activeBtn"
      :class="{ menuColorPattern: colorPattern === 1 }"
    >
      <span class="line line_01" :class="{ btn_line01: isOpen, active: isOpen }"></span>
      <span class="line line_02" :class="{ btn_line02: isOpen }"></span>
      <span class="line line_03" :class="{ btn_line03: isOpen, active: isOpen }"></span>
    </div>
    <!--サイドメニュー-->
    <transition name="hamburger__menu">
      <div class="hamburger__menu" v-show="isOpen">
        <div class="container shadow mb-5">
          <div @click="closeBtn" class="bg-white shadow-lg mb-5">
            <router-link :to="{ name: 'Home' }"
              ><img
                class="menu__logo"
                alt="株式会社F.G.L,愛知県,名古屋市,資産運用コンサルティング"
                src="../assets/img/top/logo.png"
            /></router-link>
          </div>
          <ul>
            <li @click="closeBtn">
              <router-link :to="{ name: 'Home' }" replace> <strong>TOP</strong></router-link>
            </li>
            <li @click="closeBtn">
              <router-link :to="{ name: 'Seminar' }" replace>
                <strong>セミナー</strong></router-link
              >
            </li>
            <li>
              <strong class="contact__title">ーお問い合わせー</strong>
            </li>
            <li>
              <a href="tel:0527462831"
                ><v-avatar color="#E67928" size="42"
                  ><img src="../assets/img/top/call_white_24dp.svg" class="px-2" alt="John"
                /></v-avatar>
              </a>
            </li>
            <li>
              <a href="https://gatelabo.jp/form/contact.php"
                ><v-avatar color="#E67928" size="42"
                  ><img src="../assets/img/top/email_white_24dp.svg" class="px-2" alt="John"
                /></v-avatar>
              </a>
            </li>
            <li @click="closeBtn">
              <router-link :to="{ name: 'Privacy' }" replace>
                <strong>プライバシーポリシー</strong></router-link
              >
            </li>
            <li @click="closeBtn">
              <router-link :to="{ name: 'TradeRow' }" replace>
                <strong>特定商取引法</strong></router-link
              >
            </li>
          </ul>
          <!-- <div class="bg-white py-6">
            <a class="grid grid-cols-5 mail-wrap" href="http://inouecd.jp/form/contact.php">
              <img
                class="py-2 w-20 col-span-1"
                alt="井上自動車販売 中古自動車 豊田市 愛知県"
                src="../assets/img/top/mail-logo.gif"
              /><span class="col-span-4 mail-text">お問い合わせはこちらから</span>
            </a>
          </div> -->
        </div>
      </div>
    </transition>
    <transition>
      <div class="mask" v-show="isCloseMask" @click="closeBtn"></div>
    </transition>
  </div>
</template>

<script>
export default {
  created() {
    this.colorPattern = this.$router.currentRoute.meta.menuColorPattern;
  },
  data: function () {
    return {
      colorPattern: null,
      isOpen: false,
      isCloseMask: false,
    };
  },
  watch: {
    $route() {
      this.colorPattern = this.$router.currentRoute.meta.menuColorPattern;
      console.log(this.$router.currentRoute.name);
    },
  },
  methods: {
    activeBtn() {
      this.isOpen = !this.isOpen;
      this.isCloseMask = !this.isCloseMask;
    },
    closeBtn() {
      this.isOpen = !this.isOpen;
      this.isCloseMask = !this.isCloseMask;
    },
  },
};
</script>

<style lang="scss" scoped>
.hamburger__container {
  display: block;
  .main_logo {
    position: fixed;
    top: 64px;
    left: 24px;
    width: 130px;
    cursor: pointer;
    z-index: 5;
    img {
      margin-top: 100px;
      max-width: 90%;
    }
  }
  .contact_us {
    position: fixed;
    top: 16px;
    right: 20vw;
    width: 43px;
    cursor: pointer;
    z-index: 5;
  }
  .hamburger__btn {
    position: fixed;
    top: 10.5px;
    right: 2vw;
    width: 70px;
    height: 72px;
    cursor: pointer;
    z-index: 50;

    .line {
      position: absolute;
      top: 0;
      left: 20px;
      width: 30px;
      height: 2px;
      background: #333333;
      // background: #fff;
      text-align: center;
      transition: all 0.5s;
      // background: rgba(255, 255, 255, 1);
    }
    .line_01 {
      top: 16px;
      // transition: 0.4s ease;
      transition-duration: 0.4s;
    }

    .line_02 {
      top: 26px;
      // transition: 0.4s ease;
      transition-duration: 0.4s;
    }

    .line_03 {
      top: 36px;
      // transition: 0.4s ease;
      transition-duration: 0.4s;
    }
    [class*="active"] {
      transition: 0.4s ease;
      background: #ffffff;
    }
  }
  .menuColorPattern > .line {
    background: #fff;
  }

  .btn_line01 {
    transform: translateY(10px) rotate(-315deg);
    transition: 0.4s ease;
  }
  .btn_line02 {
    transition: 0.2s ease;
    opacity: 0;
  }
  .btn_line03 {
    transform: translateY(-10px) rotate(315deg);
    transition: 0.4s ease;
  }

  // サイドメニュー
  .hamburger__menu-enter-active,
  .hamburger__menu-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }
  .hamburger__menu-enter {
    opacity: 0;
    transform: translateX(-220px);
  }
  .hamburger__menu-leave-to {
    opacity: 0;
    transform: translateX(-220px);
  }
  .hamburger__menu-leave,
  .hamburger__menu-enter-to {
    opacity: 1;
  }

  .hamburger__menu {
    background-color: #ffffff;
    z-index: 30;
    position: fixed;
    width: 255px;
    height: 100vh;
    top: 0;
    left: 0;

    .container {
      // background-color: #18b0e7;
      .menu__logo {
        width: 90px;
        margin: 36px 0 0 0;
      }
    }
    ul {
      // background-color: #18b0e7;
      // padding: 32px 0;
      // padding-top: 8px;
      // text-align: center;
      padding: 0 0 0 0;
      // padding-bottom: 18px;
      // text-align: start;
    }

    li {
      text-align: center;
      padding: 16px 0 16px 0;
      list-style: none;
      line-height: 1;
    }

    a {
      text-decoration: none;
    }
    strong {
      color: #e67928;
      font-size: 14px;
      text-decoration: none;
      padding-bottom: 6px;
      position: relative;
      display: inline-block;
      text-decoration: none;
      transition: 0.4s ease;
    }
    strong:after {
      content: "";
      width: 6px;
      height: 6px;
      border: 0;
      border-top: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
      position: absolute;
      top: 36%;
      right: -24px;
      margin-top: -4px;
      transform: rotate(45deg);
      transition: 0.4s ease;
    }
    strong:hover {
      color: rgb(66, 66, 66);
      transition: 0.4s ease;
      cursor: pointer;
    }

    strong:hover:after {
      content: "";
      width: 6px;
      height: 6px;
      border: 0;
      border-top: solid 2px rgb(66, 66, 66);
      border-right: solid 2px rgb(66, 66, 66);
      position: absolute;
      top: 36%;
      right: -30px;
      margin-top: -4px;
      transform: rotate(45deg);
      transition: 0.4s ease;
      cursor: pointer;
    }
    span {
      display: block;
      color: #e67928;
      font-size: 12px;
      font-weight: 600;
      padding: 6px 0px;
      margin-left: -6px;
      transition: 0.4s ease;
    }
    .contact__title {
      color: #463e38;
    }
    .mail-wrap {
      margin: 0 30px;
      padding: 0;
      border-top: solid 0.13em rgb(226, 209, 209);
      border-bottom: solid 0.13em rgb(226, 209, 209);
    }
    .mail-text {
      color: #a1a0a0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
    }
    .mail-text:hover {
      color: #141414;
      transition: 0.4s ease;
    }
  }

  .mask {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.812);
    z-index: 1;
    cursor: pointer;
  }
}
@media (min-width: 768px) {
  .hamburger__container {
    display: none;
  }
}
// @media (min-width: 1024px) {
//   .main_logo {
//     position: fixed;
//     top: 18px;
//     left: 20px;
//     width: 180px;
//   }
// }
</style>
