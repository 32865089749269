<template>
  <div class="privacy">
    <MiddleTitle />
    <hr />
    <PrivacyText />
    <Footer />
  </div>
</template>

<script>
import MiddleTitle from "@/components/PrivacyMiddleTitle.vue";
import PrivacyText from "@/components/PrivacyText.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    MiddleTitle,
    PrivacyText,
    Footer,
  },
  created() {
    this.moveToTop();
  },
  methods: {
    moveToTop() {
      const duration = 500; // 移動速度（1秒で終了）
      const interval = 25; // 0.025秒ごとに移動
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {
        window.scrollBy(0, step); // スクロール位置を移動

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  background-color: #e4e0dd;
  hr {
    width: 85%;
    margin: auto;
    background-color: rgb(145, 140, 136);
  }
}
@media (min-width: 768px) {
  .privacy {
    background-color: #e4e0dd;
    hr {
      width: 70%;
      margin: auto;
      background-color: rgb(145, 140, 136);
    }
  }
}
</style>
