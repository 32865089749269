<template>
  <div id="seminar">
    <Jumbotron />
    <Nayami />
    <Select />
    <Service />
    <Outline />
    <Customer />
    <Footer />
  </div>
</template>

<script>
import Jumbotron from "@/components/SeminarJumbotron.vue";
import Nayami from "@/components/SeminarNayami.vue";
import Select from "@/components/SeminarSelect.vue";
import Service from "@/components/SeminarService.vue";
import Outline from "@/components/SeminarOutline.vue";
import Customer from "@/components/SeminarCustomer.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "seminar",

  components: {
    Jumbotron,
    Nayami,
    Select,
    Service,
    Outline,
    Customer,
    Footer,
  },
  created() {
    this.moveToTop();
  },
  methods: {
    moveToTop() {
      const duration = 500; // 移動速度（1秒で終了）
      const interval = 25; // 0.025秒ごとに移動
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {
        window.scrollBy(0, step); // スクロール位置を移動

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },
  },
};
</script>

<style lang="scss" scoped>
#seminar {
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3",
    "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
