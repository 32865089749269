<template>
  <!-- <v-row> -->
  <ul>
    <li>
      <p class="title">{{ title }}</p>
      <p class="discription">{{ discription }}</p>
    </li>
  </ul>
  <!-- </v-row> -->
</template>

<script>
export default {
  props: ["title", "discription"],
};
</script>
<style lang="scss" scoped>
ul {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  // align-items: center;
  padding: 0;
  margin: 6px 0;
  list-style: none;
  li {
    // background-color: chartreuse;
    width: 100%;
    margin: 4px;
    .title {
      color: #fff;
      font-size: 12px !important;
      font-weight: 600;
      background-color: #463e38;
      margin: 0;
      line-height: 2.4;
    }
    .discription {
      color: #e67928;
      font-size: 13px !important;
      font-weight: 600;
      padding: 12px 0px;
      margin: 0;
      line-height: 1.2;
    }
  }
}
@media (min-width: 768px) {
  ul {
    width: 48%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    // align-items: center;
    padding: 0;
    margin: 6px auto;
    list-style: none;
    li {
      // background-color: chartreuse;
      width: 100%;
      // margin: 4px;
      margin: 12px 0;
      .title {
        color: #fff;
        font-size: 16px !important;
        font-weight: 600;
        background-color: #463e38;
        margin: 0;
        line-height: 2.8;
      }
      .discription {
        color: #e67928;
        font-size: 24px !important;
        font-weight: 600;
        padding: 12px 0px;
        margin: 0;
        line-height: 1.2;
      }
    }
  }
}
</style>
