<template>
  <div class="header">
    <div class="inner-header flex">
      <div class="main_logo">
        <router-link :to="{ name: 'Home' }"
          ><img
            alt="株式会社F.G.L,愛知県,名古屋市,資産運用コンサルティング"
            src="../assets/img/top/logo.png"
        /></router-link>
        <div class="link__wrap">
          <p class="link__seminar">
            <router-link :to="{ name: 'Seminar' }" replace> <strong>セミナー</strong></router-link>
          </p>
          <p class="link__border"></p>
          <div class="description__wrap">
            <div class="content__description3">
              <a href="tel:0527462831"
                ><v-avatar color="#E67928" size="44"
                  ><img src="../assets/img/top/call_white_24dp.svg" class="px-2 mt-1" alt="John"
                /></v-avatar>
              </a>
            </div>
            <div class="content__description3">
              <a href="https://gatelabo.jp/form/contact.php"
                ><v-avatar color="#E67928" size="44"
                  ><img src="../assets/img/top/email_white_24dp.svg" class="px-2 mt-1" alt="John"
                /></v-avatar>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="content">
        <span>我々の会社F.G.LとはFIRE.GATE.LABの略称です。</span><br />
        <span class="spacing">&nbsp;</span>
        <strong class="sp">FIRE = financial independence, retire early</strong>
        <strong class="pc">FIRE = <br />financial independence, <br />retire early</strong>
        <span class="spacing2">&nbsp;</span>
        <span
          >直訳すると「経済的自立と早期退職」。 <br />なるべく早期にサラリーマン生活を終え、
          資産運用などを中心に生活していくライフプランの扉を研究する、提供することを使命とします。</span
        >
        <Button />
      </p>
    </div>
  </div>
</template>

<script>
import Button from "@/components/GoSeminarButton.vue";
export default {
  name: "Jumbotron",
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  text-align: center;
  background-image: url(../assets/img/top/main-sp.jpg);
  color: #333;
  background-size: cover;
  // z-index: -1;

  .inner-header {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    .main_logo {
      position: absolute;
      top: 64px;
      left: 24px;
      width: 130px;
      cursor: pointer;
      z-index: 5;
      img {
        margin-top: 100px;
        max-width: 90%;
      }
      .link__wrap {
        display: none;
      }
    }
  }

  .flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    // align-items: end;
    text-align: center;
    padding-top: 77vh;
    // position: absolute;
  }
  .content {
    font-size: 13px;
    color: #fff;
    padding: 0 36px 24px 36px;
    // position: relative;
    // height: 20vh;
    text-align: left;
    .spacing {
      display: none;
    }
    .spacing2 {
      display: none;
    }
    .sp {
      display: block;
      font-size: 14px;
      font-weight: 500;
    }
    .pc {
      display: none;
    }
  }
}
.header::after {
  content: "";
  background: linear-gradient(rgba(156, 207, 212, 0.3), rgba(0, 0, 0, 0));
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 45vh;
  z-index: -1;
}
/*Shrinking for mobile*/
@media (min-width: 768px) {
  .header {
    position: relative;
    text-align: center;
    background-image: url(../assets/img/top/main-pc.jpg);
    color: #333;
    background-blend-mode: color-burn;
    background-size: cover;
    // background-repeat: no-repeat;
    background-position: 30% 90%;
    // z-index: -1;
    .flex {
      /*Flexbox for containers*/
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      padding-top: 0;
    }
    .inner-header {
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
      .main_logo {
        position: absolute;
        top: -24px;
        left: 54px;
        width: 130px;
        cursor: pointer;
        z-index: 5;
        img {
          margin-top: 100px;
          max-width: 90%;
        }
        .link__wrap {
          display: block;
          .link__seminar {
            color: #e67928;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin: 0;
            padding-top: 36px;
            padding-bottom: 12px;
          }
          p {
            color: #e67928;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin: 0;
            a {
              text-decoration: none;
              strong {
                color: #e67928;
                font-size: 18px;
                position: relative;
              }
            }
          }
          .link__border {
            margin-left: 66px;
            height: 15vh;
            border-left: solid 1px #e67928;
          }
          .description__wrap {
            // display: flex;
            // flex-wrap: wrap;
            padding: 0px 0 0 0px;
            .content__description3 {
              padding-top: 12px;
              text-decoration: none;
              // width: calc(100% / 5);
              padding: 12px 0;
            }
          }
        }
      }
    }
    .content {
      font-size: 22px;
      color: #fff;
      padding: 80px 36px 0 280px;
      letter-spacing: 1.2px;
      // position: relative;
      // height: 20vh;
      text-align: left;
      span {
        display: block;
        line-height: 1.8;
        width: 60%;
      }
      .spacing {
        display: block;
        font-size: 1px;
        line-height: 1;
      }
      .spacing2 {
        display: block;
      }
      .sp {
        display: none;
        font-size: 60px;
        line-height: 1.2;
        letter-spacing: 2.8px;
        font-weight: 700;
        font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3",
          "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .pc {
        display: block;
        font-size: 60px;
        line-height: 1.2;
        letter-spacing: 2.8px;
        font-weight: 700;
        font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3",
          "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
@media (min-width: 1360px) {
  .header {
    position: relative;
    text-align: center;
    background-image: url(../assets/img/top/main-pc.jpg);
    color: #333;
    background-blend-mode: color-burn;
    background-size: cover;
    // background-repeat: no-repeat;
    background-position: 50% 90%;
    // z-index: -1;
    .flex {
      /*Flexbox for containers*/
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
    }
    .content {
      font-size: 24px;
      color: #fff;
      padding: 100px 36px 0 240px;
      // position: relative;
      // height: 20vh;
      text-align: left;
      span {
        display: block;
        line-height: 1.8;
        width: 60%;
      }
      .spacing {
        display: block;
        font-size: 1px;
        line-height: 1;
      }
      .spacing2 {
        display: block;
      }
      strong {
        font-size: 60px;
        line-height: 1.2;
        font-weight: 700;
        font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3",
          "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
</style>
