<template>
  <div>
    <div class="text__wrap">
      <p class="text">
        お客様一人一人の心の奥底に眠っているニードを引き出し、攻めの投資から守りの投資まで、お客様のご要望に応じ、資産運用のご提案・設計を余すことなく行います。
      </p>
      <v-img
        class="text__img"
        alt="顧客満足度,アドバイス,高い信用"
        src="../assets/img/top/service.png"
      ></v-img>
    </div>
    <div class="contents__wrap">
      <div class="contens__wrap__wrap">
        <p class="contents__text">
          資産運用・形成についてこのようなお悩みをお持ちではありませんか？
        </p>
        <Nayami
          class="contens"
          v-for="(content, index) in contens"
          :key="index"
          :discription="content.discription"
        />
        <p class="contents__strong">
          <span
            >当社の強みを生かした国内外の豊富な事例を、実務に携わるファンドマネージャーが紹介いたします。</span
          >
        </p>
        <v-img
          class="contents__img"
          alt="顧客満足度,アドバイス,高い信用"
          src="../assets/img/top/service-2.png"
          max-height="350"
          max-width="350"
        ></v-img>
      </div>
    </div>
    <div class="select__wrap">
      <div class="select__wrap__wrap">
        <p class="select__title">選ばれる理由</p>
        <p class="select__text">
          実務に基づいた専門知識と豊富な実績をベースに、資産運用を組み立てるアイデアを、お客様お一人おひとりの要望に合わせて組み上げ、お客様の課題を解決する力が魅力です。
        </p>
        <Strength
          v-for="(content, index) in strength"
          :key="index"
          :title="content.title"
          :discription="content.discription"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Strength from "./ServiceStrength.vue";
import Nayami from "./ServiceNayami.vue";
import strength from "@/assets/json/strength.json";
import contens from "@/assets/json/nayami.json";
export default {
  components: {
    Nayami,
    Strength,
  },
  data() {
    return {
      contens: contens,
      strength: strength,
    };
  },
};
</script>

<style lang="scss" scoped>
.text__wrap {
  margin: 0 0 56px 0;
  display: flex;
  flex-wrap: wrap;
  .text {
    font-size: 14px;
    padding: 24px 36px;
    text-align: left;
  }
  .text__img {
    margin: auto;
    padding: 0;
    // width: 250px;
    max-height: 250px;
    max-width: 250px;
  }
}
.contents__wrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #e4e0dd;
  padding: 24px 0;
  position: relative;
  .contents__text {
    color: #e67928;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
    margin: 24px 42px;
    padding: 24px 0 0 0;
    text-align: left;
    border-top: solid 1px #e67928;
  }
  .contents__text::before {
    position: absolute;
    top: 46px;
    left: 11%;
    content: "";
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    background-color: #e67928;
    transform: translate(-50%, 0);
  }
  .contents__text::after {
    position: absolute;
    top: 46px;
    left: 89%;
    content: "";
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    background-color: #e67928;
    transform: translate(-50%, 0);
  }
  .contents__strong {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.6;
    text-align: left;
    padding: 18px;
    margin: 30px 32px 48px 32px;
    border: double 4px #fff;
    background-color: #e67928;
    span {
      margin: 0;
      padding: 0;
    }
  }
  .contents__img {
    margin: auto;
    padding: 0;
    width: 250px;
  }
}
.select__wrap {
  background-color: #e4e0dd;
  .select__wrap__wrap {
    padding: 18px 36px 54px 36px;
    display: flex;
    flex-wrap: wrap;
    .select__title {
      color: #e67928;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.6;
      padding: 24px 0 0 0;
      text-align: left;
      border-bottom: solid 1px #e67928;
      width: 100%;
    }
    .select__text {
      font-size: 14px;
      padding: 12px 0;
      text-align: left;
    }
  }
}

@media (min-width: 768px) {
  .text__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 72px;
    .text {
      font-size: 20px;
      padding: 36px 66px;
      line-height: 2;
      text-align: left;
    }
    .text__img {
      margin: auto;
      padding: 0 0 36px 0;
      // width: 100%;
      max-height: 350px;
      max-width: 350px;
    }
  }
  .contents__wrap {
    background-color: #e4e0dd;
    .contens__wrap__wrap {
      display: flex;
      flex-wrap: wrap;
      background-color: #e4e0dd;
      padding: 24px 0;
      position: relative;
      width: 70%;
      margin: auto;
      .contents__text {
        width: 100%;
        color: #e67928;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.8;
        // margin: 24px 42px;
        padding: 48px 0 0 0;
        text-align: center;
        border-top: solid 1px #e67928;
        margin: 36px 0;
        // margin-left: auto;
        // margin-right: auto;
      }
      .contents__text::before {
        position: absolute;
        top: 55px;
        left: 0%;
        content: "";
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: #e67928;
        transform: translate(-50%, 0);
      }
      .contents__text::after {
        position: absolute;
        top: 55px;
        left: 100%;
        content: "";
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: #e67928;
        transform: translate(-50%, 0);
      }
      .contents__strong {
        width: 100%;
        color: #fff;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.6;
        text-align: left;
        padding: 18px 66px;
        margin: 48px 0 48px 0;
        border: double 4px #fff;
        background-color: #e67928;
        span {
          margin: 0;
          padding: 0;
        }
      }
      .contents__img {
        margin: auto;
        padding: 0;
        width: 100%;
      }
    }
  }
  .select__wrap {
    background-color: #e4e0dd;
    padding: 18px 36px 66px 36px;
    .select__wrap__wrap {
      padding: 18px 0px;
      display: flex;
      flex-wrap: wrap;
      width: 70%;
      margin: auto;
      .select__title {
        color: #e67928;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.6;
        padding: 24px 0 0 0;
        text-align: left;
        border-bottom: solid 1px #e67928;
        width: 100%;
      }
      .select__text {
        font-size: 20px;
        padding: 12px 0;
        text-align: left;
        line-height: 1.8;
      }
    }
  }
}
</style>
