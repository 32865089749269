<template>
  <div class="header">
    <div class="inner-header flex">
      <div class="main_logo">
        <router-link :to="{ name: 'Home' }"
          ><img
            alt="株式会社F.G.L,愛知県,名古屋市,資産運用コンサルティング"
            src="../assets/img/top/logo.png"
        /></router-link>
        <div class="link__wrap">
          <p class="link__seminar">
            <router-link :to="{ name: 'Seminar' }" replace> <strong>セミナー</strong></router-link>
          </p>
          <p class="link__border"></p>
          <div class="description__wrap">
            <div class="content__description3">
              <a href="tel:0527462831"
                ><v-avatar color="#E67928" size="44"
                  ><img src="../assets/img/top/call_white_24dp.svg" class="px-2 mt-1" alt="John"
                /></v-avatar>
              </a>
            </div>
            <div class="content__description3">
              <a href="https://gatelabo.jp/form/contact.php"
                ><v-avatar color="#E67928" size="44"
                  ><img src="../assets/img/top/email_white_24dp.svg" class="px-2 mt-1" alt="John"
                /></v-avatar>
              </a>
            </div>
          </div>
        </div>
      </div>
      <h2>
        <span>「Fire」を目指す方のための<br />資産運用セミナー</span><br /><strong>FIRE</strong>
      </h2>
      <p class="content">
        FIRE = financial independence, retire early<br />
        直訳すると「経済的自立と早期退職」。 <br />なるべく早期にサラリーマン生活を終え、
        資産運用などを中心に生活していくライフプラン。<br />国内外の豊富な事例を紹介。
      </p>
      <ContactBtn />
      <!-- <div class="main_logo">
        <router-link to="/"
          ><img
            alt="株式会社F.G.L,愛知県,名古屋市,資産運用コンサルティング"
            src="../assets/img/top/logo.png"
        /></router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import ContactBtn from "@/components/SeminarContactButton.vue";
export default {
  name: "SeminarJumbotron",

  components: {
    ContactBtn,
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  text-align: center;
  background-image: url(../assets/img/seminar/seminar_main_sp.jpg);
  color: #333;
  background-size: cover;
  // z-index: -1;

  .inner-header {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    .main_logo {
      display: none;
    }
  }

  .flex {
    /*Flexbox for containers*/
    // display: flex;
    justify-content: center;
    // align-items: end;
    text-align: center;
    padding-top: 100px;
    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 2px;
      span {
        font-size: 15px;
      }
      strong {
        font-size: 36px;
        // font-weight: 500;
        letter-spacing: 8px;
        line-height: 3;
      }
    }
    .content {
      width: 100;
      font-size: 13px;
      color: #fff;
      padding: 0 36px 24px 36px;
      line-height: 1.8;
    }
  }
}
.header::after {
  content: "";
  background: linear-gradient(rgba(156, 207, 212, 0.3), rgba(0, 0, 0, 0));
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 45vh;
  z-index: -1;
}
/*Shrinking for mobile*/
@media (min-width: 768px) {
  .header {
    position: relative;
    text-align: center;
    background-image: url(../assets/img/seminar/seminar_main_pc.jpg);
    color: #333;
    background-size: cover;
    // z-index: -1;

    .inner-header {
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
      .main_logo {
        display: block;
        position: absolute;
        top: -24px;
        left: 54px;
        width: 130px;
        cursor: pointer;
        z-index: 5;
        img {
          margin-top: 100px;
          max-width: 90%;
        }
        .link__wrap {
          display: block;
          .link__seminar {
            color: #e67928;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin: 0;
            padding-top: 36px;
            padding-bottom: 12px;
            font-family: Avenir, Helvetica, Arial, Verdana, Roboto, "Droid Sans", "游ゴシック",
              YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN",
              "ＭＳ Ｐゴシック", sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          p {
            color: #e67928;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin: 0;
            a {
              text-decoration: none;
              strong {
                color: #e67928;
                font-size: 18px;
                position: relative;
              }
            }
          }
          .link__border {
            margin-left: 66px;
            height: 15vh;
            border-left: solid 1px #e67928;
          }
          .description__wrap {
            // display: flex;
            // flex-wrap: wrap;
            padding: 0px 0 0 0px;
            .content__description3 {
              padding-top: 12px;
              text-decoration: none;
              // width: calc(100% / 5);
              padding: 12px 0;
            }
          }
        }
      }
    }

    .flex {
      /*Flexbox for containers*/
      // display: flex;
      justify-content: center;
      // align-items: end;
      text-align: center;
      padding-top: 100px;
      h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 2px;
        span {
          font-size: 26px;
        }
        strong {
          font-size: 54px;
          // font-weight: 500;
          letter-spacing: 8px;
          line-height: 3;
        }
      }
      .content {
        width: 50%;
        margin: auto;
        font-size: 24px;
        color: #fff;
        padding: 0 36px 24px 36px;
        line-height: 1.8;
      }
    }
  }
  .header::after {
    content: "";
    background: linear-gradient(rgba(156, 207, 212, 0.3), rgba(0, 0, 0, 0));
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 45vh;
    z-index: -1;
  }
}
// @media (min-width: 1024px) {
//   .header {
//     position: relative;
//     text-align: center;
//     background-image: url(../assets/img/top/main-sp.png);
//     color: #333;
//     background-blend-mode: color-burn;
//     background-size: cover;
//     // background-repeat: no-repeat;
//     background-position: 50% 70%;
//     z-index: -1;

//     .inner-header {
//       height: 75vh;
//       width: 100%;
//       margin: 0;
//       padding: 0;
//       writing-mode: horizontal-tb;
//     }
//     .flex {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       text-align: center;
//     }
//   }
// }
</style>
