<template>
  <div class="privacy__content">
    <p class="privacy__title">{{ title }}</p>
    <p class="privacy__description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "description"],
};
</script>

<style lang="scss" scoped>
.privacy__content {
  font-size: 13px;
  font-weight: 600;
  color: rgb(66, 59, 53);
  text-align: left;
  padding: 12px 30px 0 30px;
  line-height: 2;
  .privacy__title {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .privacy__content {
    font-size: 16px;
    font-weight: 600;
    color: rgb(66, 59, 53);
    text-align: left;
    padding: 12px 30px 12px 0px;
    line-height: 2;
    .privacy__title {
      margin: 0;
      font-size: 18px;
    }
  }
}
</style>
