<template>
  <v-app>
    <Jumbotron />
    <MiddleTitle />
    <Service />
    <CustomerTitle />
    <Customer />
    <ContactTitle />
    <ContactButton />
    <Footer />
  </v-app>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import MiddleTitle from "@/components/MiddleTitle.vue";
import Service from "@/components/Service.vue";
import CustomerTitle from "@/components/MiddleTitleCustomer.vue";
import Customer from "@/components/Customer.vue";
import ContactTitle from "@/components/MiddleTitleContact.vue";
import ContactButton from "@/components/ContactButton.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",

  components: {
    Jumbotron,
    MiddleTitle,
    Service,
    CustomerTitle,
    Customer,
    ContactTitle,
    ContactButton,
    Footer,
  },
  created() {
    this.moveToTop();
  },
  methods: {
    moveToTop() {
      const duration = 500; // 移動速度（1秒で終了）
      const interval = 25; // 0.025秒ごとに移動
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {
        window.scrollBy(0, step); // スクロール位置を移動

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },
  },
};
</script>
