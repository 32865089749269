<template>
  <div>
    <p class="privacy__text">
      株式会社F.G.Lでは、お客様の個人情報保護について以下の事を厳守しております。
    </p>
    <Texts
      v-for="(content, index) in contens"
      :key="index"
      :title="content.title"
      :description="content.description"
    />
    <p class="privacy__company__name">株式会社F.G.L</p>
  </div>
</template>

<script>
import Texts from "@/components/PrivacyDescription.vue";
import contens from "@/assets/json/privacy.json";
export default {
  components: {
    Texts,
  },
  data() {
    return {
      contens: contens,
    };
  },
};
</script>
<style lang="scss" scoped>
div {
  padding: 0 24px 36px 24px;
  .privacy__text {
    font-size: 13px;
    font-weight: 600;
    color: rgb(66, 59, 53);
    text-align: left;
    padding: 42px 30px 0 30px;
  }
  .privacy__company__name {
    font-size: 13px;
    font-weight: 600;
    color: rgb(66, 59, 53);
    text-align: right;
    padding: 12px 30px 0 30px;
  }
}
@media (min-width: 768px) {
  div {
    width: 70%;
    margin: auto;
    padding: 12px 0 36px 0;
    .privacy__text {
      font-size: 20px;
      font-weight: 600;
      color: rgb(66, 59, 53);
      text-align: center;
      padding: 42px 0px 0 0px;
    }
    .privacy__company__name {
      font-size: 20px;
      font-weight: 600;
      color: rgb(66, 59, 53);
      text-align: right;
      padding: 12px 30px 0 30px;
    }
  }
}
</style>
