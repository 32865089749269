<template>
  <div>
    <div class="img"></div>
    <p class="text">お客様の声</p>
    <ul>
      <Customer
        v-for="(content, index) in Voice"
        :key="index"
        :title="content.title"
        :discription="content.discription"
      />
    </ul>
  </div>
</template>

<script>
import Customer from "./CustomerVoice.vue";
import Voice from "@/assets/json/seminarCustomerVoice.json";
export default {
  components: { Customer },
  data() {
    return {
      Voice: Voice,
    };
  },
};
</script>
<style lang="scss" scoped>
div {
  padding: 24px 0 36px 0;
  background-color: #e4e0dd;
  .img {
    width: 80%;
    margin: auto;
    position: relative;
    text-align: center;
    background-image: url(../assets/img/seminar/seminar_obi.png);
    height: 2vh;
    background-size: contain;
  }
  .text {
    color: rgb(66, 59, 53);
    margin: 0;
    padding: 0 0 0 0;
    font-size: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
}
@media (min-width: 768px) {
  div {
    padding: 24px 0 60px 0;
    background-color: #e4e0dd;
    .img {
      width: 75%;
      margin: auto;
      position: relative;
      text-align: center;
      background-image: url(../assets/img/seminar/obi-pc.png);
      background-position: center;
      height: 2vh;
      background-size: cover;
    }
    .text {
      color: rgb(66, 59, 53);
      margin: 0;
      padding: 36px 0;
      font-size: 24px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      list-style: none;
      margin: auto;
    }
  }
}
</style>
