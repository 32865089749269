<template>
  <div>
    <p class="service__number">{{ number }}</p>
    <p class="service__title">{{ title }}</p>
    <p class="service__description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: ["number", "title", "description"],
};
</script>
<style lang="scss" scoped>
div {
  color: #fff;
  .service__number {
    font-size: 26px;
    color: rgb(255, 251, 0);
    border-bottom: 1px solid #646464;
    width: 72%;
    margin: auto;
    padding-bottom: 3px;
  }
  .service__title {
    font-size: 18px;
    padding: 12px 0 12px 0;
  }
  .service__description {
    font-size: 12px;
    line-height: 1.8;
    width: 72%;
    margin: auto;
    text-align: left;
    padding-bottom: 48px;
  }
}
@media (min-width: 768px) {
  div {
    width: 50%;
    color: #fff;
    z-index: 1;
    padding-bottom: 24px;
    .service__number {
      font-size: 26px;
      color: rgb(255, 251, 0);
      border-bottom: 1px solid #a7a7a7;
      width: 72%;
      margin: auto;
      padding-bottom: 3px;
    }
    .service__title {
      font-size: 24px;
      padding: 12px 0 12px 0;
    }
    .service__description {
      font-size: 18px;
      line-height: 2;
      width: 72%;
      margin: auto;
      text-align: left;
      padding-bottom: 48px;
    }
  }
}
</style>
