<template>
  <div class="btn__wrap">
    <a v-if="contactDisplay" href="https://gatelabo.jp/form/contact.php">
      <p>{{ label }}</p></a
    >
    <a v-if="!contactDisplay" href="tel:0527462831"
      ><p>{{ label }}</p></a
    >
  </div>
</template>

<script>
export default {
  name: "seminarContactBtn",
  props: {
    contactDisplay: { type: Boolean },
  },
  computed: {
    label() {
      if (this.contactDisplay) return "メールでのお申し込みはこちら";
      if (!this.contactDisplay) return "お電話でのお申し込みはこちら";
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  margin: 0 24px;
  padding: 30px 0 0 0;
  // background-color: #e4e0dd;
  a {
    text-decoration: none;
    p {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      background-color: #d3c9bb;
      // width: 65%;
      text-align: center;
      margin: auto;
      padding: 6px 0;
    }
  }
}
@media (min-width: 768px) {
  .btn__wrap {
    // margin: 0 24px;
    padding: 30px 0 0 0;
    width: 50%;
    margin: auto;
    // background-color: #e4e0dd;
    a {
      text-decoration: none;
      p {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #d3c9bb;
        // width: 65%;
        text-align: center;
        margin: auto;
        padding: 6px 0;
      }
    }
  }
}
@media (min-width: 1360px) {
  .btn__wrap {
    // margin: 0 24px;
    padding: 40px 0 0 0;
    width: 50%;
    margin: auto;
    // background-color: #e4e0dd;
    a {
      text-decoration: none;
      p {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        background-color: #d3c9bb;
        // width: 65%;
        text-align: center;
        margin: auto;
        padding: 6px 0;
      }
    }
  }
}
</style>
