<template>
  <ul>
    <li class="nayami__wrap">
      <p class="contents__nayami">
        <v-icon>check</v-icon><span>{{ discription }}</span>
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["discription"],
};
</script>
<style lang="scss" scoped>
ul {
  width: 80%;
  list-style: none;
  .nayami__wrap {
    width: 100%;
    background-color: #fff;
    margin: 6px 36px;
    .contents__nayami {
      text-align: left;
      padding: 6px 0px 6px 6px;
      margin: 0;
      span {
        font-size: 14px;
        padding-left: 6px;
      }
    }
  }
}
@media (min-width: 768px) {
  ul {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 48%;
    padding: 0;
    // justify-content: space-between;
    margin: 12px auto;
    .nayami__wrap {
      // justify-content: center;
      // margin: 6px 36px;
      .contents__nayami {
        text-align: left;
        // padding: 6px 6px 6px 0px;
        margin: 0;
        span {
          font-size: 18px;
          padding-left: 6px;
        }
      }
    }
  }
}
</style>
