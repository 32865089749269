<template>
  <div class="aaa">
    <div class="voice__wrap">
      <p class="voice__title">{{ title }}</p>
      <p class="discription">{{ discription }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "discription"],
};
</script>

<style lang="scss" scoped>
.aaa {
  list-style: none;
  padding: 0 0 36px 0;
  .voice__wrap {
    text-align: center;
    display: inline-block;
    padding: 12px 30px;
    margin: 0px 36px 0px 36px;
    border: solid 2px #d3c9bb;
    background-color: #d3c9bb;
    position: relative;
    .voice__title {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      padding: 6px 0;
      margin: 0;
    }
    .discription {
      color: #fff;
      font-size: 14px;
      padding-top: 12px;
      text-align: left;
      line-height: 1.6;
    }
  }
  .voice__wrap::after {
    content: "";
    border: solid 2px #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    // z-index: -1;
  }
}
@media (min-width: 768px) {
  .aaa {
    width: 50%;
    color: #fff;
    z-index: 1;
    padding-bottom: 24px;
    .voice__wrap {
      width: 100%;
      text-align: center;
      display: inline-block;
      padding: 12px 30px;
      // margin: 36px 36px 0px 36px;
      margin: 36px 24px 0px 24px;
      border: solid 2px #d3c9bb;
      background-color: #d3c9bb;
      position: relative;
      width: 90%;
      margin: 0px;
      .voice__title {
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        padding: 6px 0;
        text-align: center;
      }
      .discription {
        color: #fff;
        font-size: 20px;
        padding-top: 12px;
        text-align: left;
        line-height: 2;
      }
    }
    // .voice__wrap::after {
    //   content: "";
    //   border: solid 2px #fff;
    //   position: absolute;
    //   top: 2px;
    //   left: 2px;
    //   width: calc(100% - 4px);
    //   height: calc(100% - 4px);
    //   z-index: -1;
    // }
  }
}
</style>
