<template>
  <div>
    <div class="customer__wrap">
      <ul>
        <Customer
          v-for="(content, index) in Voice"
          :key="index"
          :title="content.title"
          :discription="content.discription"
        />
      </ul>
      <div class="img__wrap">
        <v-img
          class="img__img"
          alt="顧客満足度,アドバイス,高い信用"
          src="../assets/img/top/performance.png"
          max-height="450"
          max-width="450"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import Customer from "./CustomerVoice.vue";
import Voice from "@/assets/json/customerVoice.json";
export default {
  components: { Customer },
  data() {
    return {
      Voice: Voice,
    };
  },
};
</script>

<style lang="scss" scoped>
.customer__wrap {
  padding: 36px 0;
  ul {
    list-style: none;
    padding: 0;
  }
  .img__wrap {
    margin: 56px 0;
    // display: flex;
    // flex-wrap: wrap;
    .img__img {
      margin: auto;
      padding: 0;
      width: 100%;
      width: 300px;
    }
  }
}
@media (min-width: 768px) {
  .customer__wrap {
    width: 90%;
    margin: auto;
    padding: 30px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      list-style: none;
      margin: auto;
    }
    .img__wrap {
      margin: 56px 0;
      // display: block;
      // flex-wrap: wrap;
      .img__img {
        margin: auto;
        padding: 0;
        width: 100%;
      }
    }
  }
}
</style>
