<template>
  <v-app>
    <Nav id="nav" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Nav from "@/components/Nav.vue";
export default {
  name: "App",
  components: {
    Nav,
  },
  data: () => ({}),
  computed: {
    test() {
      return this.$router;
    },
    meta() {
      return this.$router.currentRoute.meta;
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-family: Avenir, Helvetica, Arial, Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic,
    "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
      .line {
        // position: absolute;
        // top: 0;
        // left: 20px;
        // width: 30px;
        // height: 2px;
        background: #fff;
        // text-align: center;
        // transition: all 0.5s;
        // background: rgba(255, 255, 255, 1);
      }
    }
  }
}
@media (min-width: 1024px) {
  #nav {
    padding: 48.5px 0;
  }
}
</style>
