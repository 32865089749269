<template>
  <div>
    <h3>セミナー開催概要</h3>
    <div class="seminar__wrap">
      <p class="introduction">120分に渡り事例をご紹介</p>
      <p class="date">開催日時 : 毎週水曜日</p>
      <ul>
        <li>①10 : 00 - 12 : 00</li>
        <li>②13 : 00 - 15 : 00</li>
        <li>③16 : 00 - 18 : 00</li>
      </ul>
      <p class="fee">参加料金 : 300,000円</p>
      <p class="place">
        開催場所 : 一条タワー金山<br /><span>カンファレンスルーム : </span>2105<span>号室</span>
      </p>
      <p class="address">〒456-0003<br />愛知県名古屋市熱田区波寄町９−２２</p>
      <ContactBtn :contactDisplay="true" />
      <ContactBtn :contactDisplay="false" />
    </div>
    <p class="hosoku">※投資を斡旋・仲介するものではございません。</p>
  </div>
</template>

<script>
import ContactBtn from "@/components/SeminarContactBtn.vue";
export default {
  components: {
    ContactBtn,
  },
};
</script>
<style lang="scss" scoped>
div {
  color: rgb(66, 59, 53);
  background-color: #e4e0dd;
  text-align: center;
  h3 {
    font-size: 15px;
    font-weight: 500;
    padding: 36px 0;
  }
  .seminar__wrap {
    letter-spacing: 1.5px;
    position: relative;
    text-align: center;
    background-image: url(../assets/img/seminar/seminar_waku.png);
    background-size: contain;
    background-position: center;
    margin: auto;
    width: 90%;
    height: 96vh;
    .introduction {
      font-size: 18px;
      border-bottom: 1px solid #bebebe;
      width: 72%;
      margin: auto;
      padding-top: 90px;
      padding-bottom: 3px;
    }
    .date {
      padding: 24px 0 0 0;
    }
    ul {
      list-style: none;
      li {
        line-height: 1.6;
      }
    }
    .fee {
      padding: 18px 0 0 0;
    }
    .place {
      padding: 12px 0 6px 0;
      span {
        letter-spacing: -2px;
      }
    }
    .address {
      margin: 0;
      padding: 0 0 12px 0;
      font-size: 12px;
    }
  }
  .hosoku {
    font-size: 12px;
    margin: 0;
    padding: 12px 0 42px 0;
  }
}
@media (min-width: 768px) {
  div {
    color: rgb(66, 59, 53);
    background-color: #e4e0dd;
    text-align: center;
    padding: 60px 0;
    h3 {
      font-size: 24px;
      font-weight: 500;
      padding: 36px 0;
    }
    .seminar__wrap {
      letter-spacing: 1.5px;
      position: relative;
      text-align: center;
      background-image: url(../assets/img/seminar/waku-pc-2.png);
      background-size: contain;
      margin: auto;
      width: 75%;
      height: 130vh;
      font-size: 24px;
      .introduction {
        font-size: 32px;
        border-bottom: 1px solid #bebebe;
        width: 72%;
        margin: auto;
        padding-top: 72px;
        padding-bottom: 12px;
      }
      .date {
        padding: 34px 0 0 0;
      }
      ul {
        list-style: none;
        li {
          line-height: 2;
        }
      }
      .fee {
        padding: 24px 0 0 0;
      }
      .place {
        padding: 24px 0 6px 0;
        span {
          letter-spacing: -2px;
        }
      }
      .address {
        margin: 0;
        padding: 0 0 24px 0;
        font-size: 18px;
      }
    }
    .hosoku {
      font-size: 18px;
      margin: 0;
      padding: 12px 0 42px 0;
    }
  }
}
@media (min-width: 1360px) {
  div {
    color: rgb(66, 59, 53);
    background-color: #e4e0dd;
    text-align: center;
    padding: 60px 0;
    h3 {
      font-size: 24px;
      font-weight: 500;
      padding: 36px 0;
    }
    .seminar__wrap {
      letter-spacing: 1.5px;
      position: relative;
      text-align: center;
      background-image: url(../assets/img/seminar/waku-pc-2.png);
      background-size: contain;
      margin: auto;
      width: 75%;
      height: 130vh;
      font-size: 24px;
      .introduction {
        font-size: 32px;
        border-bottom: 1px solid #bebebe;
        width: 72%;
        margin: auto;
        padding-top: 108px;
        padding-bottom: 12px;
      }
      .date {
        padding: 64px 0 0 0;
      }
      ul {
        list-style: none;
        li {
          line-height: 2;
        }
      }
      .fee {
        padding: 24px 0 0 0;
      }
      .place {
        padding: 24px 0 6px 0;
        span {
          letter-spacing: -2px;
        }
      }
      .address {
        margin: 0;
        padding: 0 0 24px 0;
        font-size: 18px;
      }
    }
    .hosoku {
      font-size: 18px;
      margin: 0;
      padding: 12px 0 42px 0;
    }
  }
}
</style>
